
import CryptoJS from "crypto-js";
import _ from 'lodash'

export const getClassFee = (category, info) => {
  return _.find(info, (inf) => inf.className === category)?.fees;
};


export const toCurrency = (number) => {
  return "GH¢ " + Number(number.toFixed(2)).toLocaleString();
};

export const maxDate = () => {
  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth() + 1;
  var year = today.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + day;
};

export const composeMessage = (info) => {
  return String(
    `Dear Parent, An Amount of ${info.amount}GHS has been paid for ${
      info.name
    } for ${info.fee_type} at ${info.date?.slice(0, 10)}. Current Balance: ${
      info.balance
    } Receipt ID: ${info.id} Thank You`
  );
};

export const checkFile = (file) => {
  const fileType = file.type;

  const fileSize = file.size / 1024;
  const accepts = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];

  return accepts.includes(fileType) && fileSize <= 2 * 1024 * 1024;
};

const encryptKey = "x-constants";

export const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    encryptKey
  ).toString();

  return data;
};

export const decryptData = (text) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, encryptKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (error) {
    return null;
  }
};


