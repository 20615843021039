import { toCurrency } from "../functions";
import { bg } from "../images";
import _ from "lodash";
import pdfMake from "pdfmake";
import fonts from "../data/fonts";

pdfMake.fonts = fonts;

export default function generate_bg(cls, data, division, term, year, group) {
  let rows = [];
  rows.push(["STUDENT ID", "NAME", "BALANCE", "CONTACT"]);

  for (var dt of data) {
    rows.push([
      dt.studentID,
      dt.name,
      toCurrency(Math.abs(dt.balance)),
      "",
    ]);
  }

  const amountLeft = Math.abs(_.sumBy(data, (dt) => dt.balance));

  const DOC_DEFF = {
    pageOrientation: "potrait",
    paperSize: "A4",
    defaultStyle: {
      font: "Sen",
      fontSize: 11,
    },
    pageMargins: [15, 15, 15, 15],
    content: [
      [
        {
          image: bg,
          width: 595,
          absolutePosition: { x: 0, y: 0 },
        },
        {
          text: group.toUpperCase() + " LIST",
          fontSize: 15,
          absolutePosition: { x: 20, y: 110 },
        },
        {
          text: "Class: " + cls + "(" + division + ") -" + term + " - " + year,
          absolutePosition: { x: 20, y: 130 },
        },
        {
          text: "Generated at: " + new Date().toLocaleString(),
          absolutePosition: { x: 20, y: 150 },
        },
        {
          text: "Total Sum: " + toCurrency(amountLeft),
          absolutePosition: { x: 20, y: 170 },
        },
        {
          absolutePosition: { x: 20, y: 200 },
          fontSize: 10,
          layout: "lightHorizontalLines",
          table: {
            widths: [100, 250, 60, 60, 100],
            body: rows,
          },
        },
      ],
    ],
  };

  pdfMake.createPdf(DOC_DEFF).print();
}
