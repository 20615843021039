var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

var { bg } = require("../images");

export default function generate_receipt(fss) {
  var doc_deff = {
    paperSize: "A5",
    pageOrientation: "landscape",
    watermark: {
      text: "GREATER HEIGHTS SCHOOL",
      color: "lightgrey",
      opacity: 0.5,
      bold: true,
      italics: true,
    },
    content: [
      {
        image: bg,
        width: 845,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: fss.last_name.toUpperCase() + " " + fss.first_name.toUpperCase(), //NAME
        style: { fontSize: 16 },
        absolutePosition: { x: 310, y: 220 },
      },
      {
        text: fss.term.toUpperCase(),
        style: { fontSize: 16 },
        absolutePosition: { x: 190, y: 270 },
      },
      {
        text: fss.student_id.toUpperCase(),
        style: { fontSize: 16 },
        absolutePosition: { x: 640, y: 270 },
      },
      {
        text: fss.id?.slice(0, 10).toUpperCase(),
        style: { fontSize: 16 },
        absolutePosition: { x: 242, y: 326 },
      },
      {
        text: fss.class.toUpperCase(),
        style: { fontSize: 16 },
        absolutePosition: { x: 590, y: 326 },
      },

      {
        text: fss.date_paid,
        style: { fontSize: 16 },
        absolutePosition: { x: 235, y: 389 },
      },
      {
        text: fss.fee_type.toUpperCase(),
        style: { fontSize: 16 },
        absolutePosition: { x: 260, y: 439 },
      },
      {
        text: fss.amount, //AMOUNTPAID
        style: { fontSize: 16 },
        absolutePosition: { x: 700, y: 389 },
      },
      {
        text: fss.balance, //BALANCE
        style: { fontSize: 16 },
        absolutePosition: { x: 650, y: 439 },
      },
    ],
  };

  return pdfMake.createPdf(doc_deff).open();
}
