export const classes = [
    {
        value: 'Nursery 1',
        text: 'Nursery 1',
        category: "Pre School",
        index: 0

    },
    {
        value: 'Nursery 2',
        text: 'Nursery 2',
        category: "Pre School",
        index: 1

    },
    {
        value: 'KG 1',
        text: 'KG 1',
        category: "Pre School",
        index: 2

    },
    {
        value: 'KG 2',
        text: 'KG 2',
        category: "Pre School",
        index: 3

    },
    {
        value: 'Basic 1',
        text: 'Basic 1',
        category: "Lower Primary",
        index: 4

    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
        category: "Lower Primary",
        index: 5

    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
        category: "Lower Primary",
        index: 6

    },
    {
        value: 'Basic 4',
        text: 'Basic 4',
        category: "Upper Primary",
        index: 7

    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
        category: "Upper Primary",
        index: 8

    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
        category: "Upper Primary",
        index: 9

    },
    {
        value: 'Basic 7',
        text: 'Basic 7',
        category: "Junior High",
        index: 10

    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
        category: "Junior High",
        index: 11

    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
        category: "Junior High",
        index: 12

    }
]

export const _days = [
    {
        text: 'Monday',
        value: "Monday",
    },
    {
        text: 'Tuesday',
        value: "Tuesday",
    },
    {
        text: 'Wednesday',
        value: "Wednesday",
    },
    {
        text: 'Thursday',
        value: "Thursday",
    },
    {
        text: 'Friday',
        value: "Friday"
    }

]

export const weeks = [
    {
        text: 'Week 1',
        value: 'Week 1'
    },
    {
        text: 'Week 2',
        value: 'Week 2'
    },
    {
        text: 'Week 3',
        value: 'Week 3'
    },
    {
        text: 'Week 4',
        value: 'Week 4'
    },
    {
        text: 'Week 5',
        value: 'Week 5'
    },
    {
        text: 'Week 6',
        value: 'Week 6'
    },
    {
        text: 'Week 7',
        value: 'Week 7'
    },
    {
        text: 'Week 8',
        value: 'Week 8'
    },
    {
        text: 'Week 9',
        value: 'Week 9'
    },
    {
        text: 'Week 10',
        value: 'Week 10'
    },
    {
        text: 'Week 11',
        value: 'Week 11'
    },
    {
        text: 'Week 12',
        value: 'Week 12'
    },
    {
        text: 'Week 13',
        value: 'Week 13'
    },
    {
        text: 'Week 14',
        value: 'Week 14'
    },
    {
        text: 'Week 15',
        value: 'Week 15'
    },
    {
        text: 'Week 16',
        value: 'Week 16'
    }
]

export const terms = [
    {
        text: "First Term",
        value: "First Term"
    },
    {
        text: "Second Term",
        value: "Second Term"
    },
    {
        text: "Third Term",
        value: "Third Term"
    }]



export const months = [
    {
        text:"January", 
        value:"January"
    },
    {
        text:"February", 
        value:"February"
    },
    {
        text:"March", 
        value:"March"
    },
    {
        text:"April", 
        value:"April"
    },
    {
        text:"May", 
        value:"May"
    },
    {
        text:"June", 
        value:"June"
    },
    {
        text:"July", 
        value:"July"
    },
    {
        text:"August", 
        value:"August"
    },
    {
        text:"September", 
        value:"September"
    },
    {
        text:"October", 
        value:"October"
    },
    {
        text:"November", 
        value:"November"
    },
    {
        text:"December", 
        value:"December"
    }
]

export const years = [
{
    text:"2023 - Academic Year",
    value:"2023/24",
    disabled:new Date().getFullYear() < 2023
},
{
    text:"2024 - Academic Year",
    value:"2024/25", 
    disabled:new Date().getFullYear() < 2024
},
{
    text:"2025 - Academic Year",
    value:2025,
    disabled: new Date().getFullYear() < 2025
}
]

export const divisions = [
    {
        text:"A",
        id:1, 
        value:"A"
    },
    {
        text:"B",
        id:1, 
        value:"B"
    },
    {
        text:"C",
        id:1, 
        value:"C"
    }
]

export const academic_years = [
    {
        text:"2023/24", 
        value:"2023/24"
    },
    {
        text:"2024/25", 
        value:"2024/25", 
        disabled: new Date().getFullYear() < 2024
    },
    {
        text:"2025/26", 
        value:"2026/26", 
        disabled: new Date().getFullYear() < 2025
    },
    {
        text:"2026/27", 
        value:"2026/27",
        disabled: new Date().getFullYear() < 2026
    },
    {
        text:"2027/28", 
        value:"2027/28",
        disabled: new Date().getFullYear() < 2027
    },
]

export const DEFAULT_STUDENT_STATE = {
    other_names: "",
    last_name: "",
    photo: "",
    gender: "",
    former_school: "",
    date_of_birth: "",
    current_class: "",
    division: "n/a",
    class_category: "",
    religion: "n/a",
    nationality: "n/a",
    arrears: 0.0,
    contact_1: "",
    contact_2: "",
    home_town: "n/a",
    place_of_birth: "n/a",
    guardian_phone: "",
    address: "n/a",
    id: new Date().getTime().toString(),
    date_registered: new Date().toISOString(),
    is_active: true,
}