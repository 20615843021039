import React, { useEffect, useState } from "react";
import Sidebar from "./side-bar";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Topbar } from "../components/layout";
import { readDocument, readDocuments } from "../services/calls";
import { exitApp } from "../services/auth";
import { getDocument, getDocuments, searchQuery } from "../services/firestore";
import { Feedback } from "../components/modals/feedback";
import { decryptData } from "../functions";

// import { getDocuments } from "../services/firestore";

export default function Layout({ Node, title }) {
  const [bills, setBills] = useState([]);
  const [classes, setClasses] = useState([]);
  const [user, setUser] = useState({});
  const [school, setSchool] = useState([]);
  // const [notes, setNotes] = useState([]);
  const [students, setStudents] = useState([]);
  const [withdrawn, setWithdrawn] = useState([]);
  const [settings, setSettings] = useState([]);
  const [fees, setFees] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [config, setConfig] = useState({});

  const [response, setResponse] = useState({
    message: "",
    type: "",
    open: false,
  });

  const navigate = useNavigate();

  const userID = decryptData(localStorage.getItem("userID"));
  const sID = decryptData(localStorage.getItem("sID"));

  useEffect(() => {
    async function readData() {
      if (userID === null) {
        // log whatever it is out
        exitApp().then(() => navigate("/login"));
      } else {
        getDocument({
          path: "adminx",
          id: userID,
          getData: setUser,
        });
        getDocument({
          path: "schoolx",
          id: sID,
          getData: setSchool,
        });
      }

      await readDocuments({
        path: `/student/`,
        getData: (data) => {
          setStudents(_.filter(data, (dt) => dt.is_active === true));
          setWithdrawn(_.filter(data, (dt) => dt.is_active === false));
        },
      });

      await readDocuments({
        path: `/class/`,
        getData: setClasses,
      });

      await readDocument({
        path: "/settings",
        id: "standard",
        getData: (data) => setConfig(data),
      });

      await readDocuments({
        path: `/fees/`,
        getData: (data) => setFees(data),
      });

      await readDocuments({
        path: `/bills/`,
        getData: (data) => setBills(data),
      });

      /*
      await readDocuments({
        path: `/messages/?call_id=${cid}`,
        getData: (data) => setNotes(data),
      }); */
    }

    try {
      readData();
    } catch (err) {
      setResponse({
        message: err.message,
        type: "error",
        open: true,
      });
    }

    searchQuery({
      path: "revenue",
      type:"schoolID",
      searchString: sID,
      getData: setSettings,
    });

    getDocuments({
      path: "expenses",
      getData: setExpenses,
    });
  }, [userID, navigate, sID]);

  const setCID = () => {};

  const data = {
    settings,
    groups: _.sortBy(classes, (cl) => cl.priority),
    config,
    fees,
    students: _.sortBy(students, (std) => std.last_name),
    withdrawn,
    user,
    bills,
    expenses,
    banner: school?.banner,
    senderID: school?.senderID,
    shouldUpdate: setCID,
    schoolID: sID,
  };

  /*
  const createAccounts = () => {
    const validPhoneNumbers = _.filter(
      students,
      (std) => parseInt(std.contact_1) !== 0
    );
    const accounts = [];
    for (var x of validPhoneNumbers) {
      accounts.push({
        uid: x.student_id?.replaceAll("-", "x"),
        phone: "+233" + x.contact_1.substr(1, 9),
        email:
          x.student_id.toLowerCase()?.replaceAll("-", ".") + "@lschoolx.com",
        password: x.defaultPwd,
      });
    }

    return accounts.slice();
  };

  const activateCreates = async () => {
    const accountsToCreate = createAccounts().slice(1,500);
    setLoading(true)
    let counts = 0;
    try {
      for (var profile of accountsToCreate) {
        const { status } = await uploadData({
          path: "/user/create-account",
          data: { ...profile },
        });
        console.log(status);
        counts += 1;
      }
      console.log(counts);
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };
*/

  return (
    <div className="flex w-screen h-screen bg-white">
      <Sidebar school={school} />
      <div className="flex flex-col flex-1 bg-gray-50">
        <Topbar title={title} user={user} notes={[]} />
        {/* Children Node */}
        <div className="flex relative  flex-1">
          <Node {...data} />
        </div>
        {response.open && (
          <Feedback
            message={response.message}
            type={response.type}
            setOpen={() => setResponse({ open: false })}
          />
        )}
      </div>
    </div>
  );
}
