import { toCurrency } from "../functions";
import fonts from '../data/fonts'
import pdfMake from 'pdfmake'
const { bg } = require("../images");
var _ = require('lodash')


pdfMake.vfs = fonts;

pdfMake.fonts = {
  Sen: {
    normal: 'Sen-Regular.ttf',
    bold: 'Sen-Bold.ttf',
    italics: 'Sen-Regular.ttf',
    bolditalics: 'Sen-ExtraBold.ttf'
  }
};


export default function generate_history(cls, data, division, term, fees_type) {
  var rows = [];
  const total = _.sumBy(data, dt => dt.amount)
  rows.push([
    { text: cls.toUpperCase(), colSpan: 4 },
    {},
    {},
    {},
    { text: "Total: " + toCurrency(total) },
  ]);
  
  rows.push([
    { text: "Name", bold: true },
    { text: "Amount", bold: true },
    { text: "Balance", bold: true },
    { text: "Date Paid", bold: true },
    { text: fees_type !== "School Fees" ? "Division" :  "Paid By", bold: true },
  ]);
  for (var dt of data) {
    rows.push([
      dt.first_name + " " + dt.last_name,
      dt.amount,
      dt.balance,
      new Date(dt.date_paid).toLocaleDateString(),
      fees_type !== "School Fees" ? dt.division  : dt.paid_by,
    ]);
  }

  const docDefinition = {
    pageOrientation: "potrait",
    paperSize: "A4",
    pageMargins: [15, 15, 15, 15],
    background: {
      image: bg,
      width: 595,
      absolutePosition: { x: 0, y: 0 },
    },
    defaultStyle:{
      font:'Sen', 
      fontSize:11,
    },
    content: [
      {
        text: "Fee Type: "  + fees_type,
        absolutePosition: { x: 15, y: 110 },
      },
      {
        text: "Term: " + term ,
        absolutePosition: { x: 15, y: 125 },
      },
      {
        text: "Division: " + division,
        absolutePosition: { x: 15, y: 140 },
      },
      {
        text: "Generated at: " + new Date().toLocaleString(),
        absolutePosition: { x: 15, y: 155 },
      },
      {
        absolutePosition: { x: 15, y: 185 },
        
        table: {
          widths: [200, 50, 50, 70, 120],
          body: rows,
        },
      },
    ],
  };

  return pdfMake.createPdf(docDefinition).print();
}
