import React, { Suspense } from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Payments from "./pages/Payment";
import { Loading } from "./components/loader";
import { Private } from "./routes";

const Login = React.lazy(() => import("./access/login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Expenses = React.lazy(() => import("./pages/Expenses"));

const Reports = React.lazy(() => import("./pages/Reports"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Records = React.lazy(() => import("./pages/Records"));
const Register = React.lazy(() => import("./pages/Register"));

export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route
            path="/dashboard"
            element={<Private Node={Dashboard} title="Dashboard" />}
          />
          <Route
            path="/"
            element={<Private Node={Dashboard} title="Dashboard" />}
          />
          <Route
            path="/payments"
            element={<Private Node={Payments} title="Payments" />}
          />
          <Route
            path="/expenses"
            element={<Private Node={Expenses} title="Expenses" />}
          />
          <Route
            path="/records"
            element={<Private Node={Records} title="Records" />}
          />
          <Route
            path="/reports"
            element={<Private Node={Reports} title="Reports" />}
          />
          <Route
            path="/settings"
            element={<Private Node={Settings} title="Settings" />}
          />
          <Route
            path="/add-student"
            element={<Private Node={Register} title="Add Student" />}
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </Suspense>
  );
}
