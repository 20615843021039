import axios from "axios";
import { decryptData } from "../functions";

// Migrating to Mongo  // ;//
// export const proxy =   "http://localhost:4000"  

export const proxy = process.env.NODE_ENV === "production" ? "https://schools-x-server.onrender.com" : "http://localhost:4000" 

export const mailerPath = "https://schools-x-mailer.onrender.com";

export const dbName = decryptData(localStorage.getItem("DBN"));
export const sID = decryptData(localStorage.getItem("sID"));
export const xmail = decryptData(localStorage.getItem("xmail"));
export const userID = decryptData(localStorage.getItem("userID"));

export const uploadData = async ({ path, data }) => {
  return axios.post(
    `${proxy + path}`,
    { ...data },
    { headers: { dbName: dbName, docID: userID, xmail: xmail  } }
  );
};

export const getCount = async ({ path, getData }) => {
  const { data } = await axios.get(`${proxy + path}`, {
    headers: { dbName: dbName, docID: userID, xmail: xmail },
  });
  getData(data);
};

export const readDocuments = async ({ path, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}`, {
      headers: { dbName: dbName, docID: userID, xmail: xmail },
    });
    getData(data);
  } catch (err) {
    console.log(err);
  }
};

export const readDocument = async ({ path, id, getData }) => {
  try {
    const { data } = await axios.get(`${proxy + path}/${id}`, {
      headers: { dbName: dbName, docID: userID, xmail: xmail },
    });
    getData(data);
  } catch (error) {
    console.log(error);
  }
};

export const updateData = async ({ path, id, data }) => {
  if (id){
    return axios.patch(`${proxy + path}/${id}`, data, {
    headers: { dbName: dbName, docID: userID, xmail: xmail },
  });
  }

  // bulk data... no id needed.
  return axios.patch(`${proxy + path}`, data, {
    headers: { dbName: dbName, docID: userID, xmail: xmail },
  });

  
};

export const deleteData = async ({ path, id }) => {
  return axios.delete(`${proxy + path}/${id}`, {
    headers: { dbName: dbName, docID: userID, xmail: xmail },
  });
};
