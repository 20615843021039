/*import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage' */

import {initializeApp} from 'firebase/app'
import {initializeFirestore, CACHE_SIZE_UNLIMITED} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import {getStorage} from 'firebase/storage'
import {getFunctions} from 'firebase/functions'

// Testing API
const firebaseConfig = {
  apiKey: "AIzaSyC6wA2dqy_SmSOJvgxTe59lrxOcVAj-6qo",
  authDomain: "logic-schools-x.firebaseapp.com",
  projectId: "logic-schools-x",
  storageBucket: "logic-schools-x.appspot.com",
  messagingSenderId: "816226170318",
  appId: "1:816226170318:web:2cb321e4434466f308a79f",
  measurementId: "G-DHJNBVNMZG"
  }

//Production


const app = initializeApp(firebaseConfig)

//set Auth Persistence 

//set Firestore Persistence

export const auth = getAuth(app)

export const db  = initializeFirestore(app,{
  cacheSizeBytes:CACHE_SIZE_UNLIMITED
})

export const storage = getStorage(app)

export const func = getFunctions(app)
