import React from "react";
import { useNavigate } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";

import logo from "../images/_.png";
import { CiViewTable } from "react-icons/ci";
import { MenuItem } from "../components/layout";

import {
  MdDashboard,
  MdShoppingCartCheckout
} from "react-icons/md";
import { TbChartInfographic, TbSettings, TbMoneybag } from "react-icons/tb";
import {TiUserAddOutline} from 'react-icons/ti'
import { exitApp } from "../services/auth";

export default function Sidebar({ school }) {
  const toggle = true
  const navigate = useNavigate()

  const isActive = (node) => {
    return window.location.pathname?.startsWith(node)
  };

  const logout = () => {
    exitApp().then(() => {
      localStorage.clear()
      navigate("/login")
    })
  }
  return (
    <div
      className={`relative z-50 shrink-0 bg-gray-800  flex flex-col h-full  px-5`}
    >
      <div className="flex items-center justify-center  my-5">
        <img src={school?.logo || logo} alt="School Logo" className="w-10 h-10 object-contain" />
        <div className={`${toggle ? "hidden" : "flex flex-col"}`}>
          <h3 className="text-md text-gray-50">{school?.name || "Logic Schoolx"}</h3>
          <p className="text-sm text-gray-50">Accountant</p>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        
          <MenuItem
            to="/dashboard"
            desc={"Dashboard"}
            Icon={MdDashboard}
            toggle={toggle}
            active={isActive("/dashboard")}
          />
        
        
          <MenuItem
          to="/payments"
            desc={"Payments"}
            Icon={TbMoneybag}
            toggle={toggle}
            active={isActive("/payments")}
          />
        
        
          <MenuItem
          to="/expenses"
            desc={"Expenses"}
            Icon={MdShoppingCartCheckout}
            toggle={toggle}
            active={isActive("/expenses")}
          />
        

        
          <MenuItem
          to="/reports"
            desc={"Reports"}
            Icon={TbChartInfographic}
            toggle={toggle}
            active={isActive("/reports")}
          />
        
        
          <MenuItem
          to="/records"
            desc={"History"}
            Icon={CiViewTable}
            toggle={toggle}
            active={isActive("/records")}
          />
        

        
          <MenuItem
          to="/add-student"
            desc={"Register"}
            Icon={TiUserAddOutline}
            toggle={toggle}
            active={isActive("/add-student")}
          />
        

        <button onClick={() => logout()}>
          <MenuItem
          to="login"
            desc={"Exit"}
            Icon={LuLogOut}
            toggle={toggle}
            active={isActive("..")}
          />
        </button>
      </div>
      <div className="absolute bottom-2 ">
        
          <MenuItem to="/settings" desc={"Settings"} Icon={TbSettings} toggle={toggle} />
       
      </div>

    </div>
  );
}

// payments, expenses, fees, records, settings, messaging
