import { Navigate } from "react-router-dom";
import Layout from "../layout";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services";
import { useEffect } from "react";
import { decryptData } from "../functions";

export const Private = ({ Node, title }) => {
  const authenticated = decryptData(localStorage.getItem("XAD"))

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        // localStorage.clear();
      }
    });
  }, []);

  return authenticated !== null ? (
    <Layout Node={Node} title={title} />
  ) : (
    <Navigate to="/login" replace />
  );
};
