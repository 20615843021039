import React, { useEffect } from "react";
import { FaChartPie } from "react-icons/fa";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { BiTrash } from "react-icons/bi";
import { BsPrinter } from "react-icons/bs";
import { maxDate, toCurrency } from "../functions";

import generate_receipt from "../generators/generate_receipt";
import { v4 as idv4 } from "uuid";
import { Feedback } from "../components/modals/feedback";
import _ from "lodash";
import { Screen } from "../components/layout";
import { HiBellAlert } from "react-icons/hi2";
import { BiSearch, BiPieChart } from "react-icons/bi";
import { Student } from "../components/pages";
import {
  deleteData,
  proxy,
  dbName,
  updateData,
  uploadData,
  xmail,
  userID,
  sID,
  readDocuments,
} from "../services/calls";
import axios from "axios";
import generate_history from "../generators/generate-class-history";
import generate_student_history from "../generators/generate-history";
import { Spin } from "../components/loader";
import { auth } from "../services";
import { searchQuery } from "../services/firestore";
import { AnimatePresence } from "framer-motion";
import Broadcast from "../components/modals/Messaging";

export default function Payments({ config, user, banner, senderID }) {
  const [cls, setClass] = useState("Nursery 1");
  const [alert, setAlert] = useState(false);
  const [sid, setSID] = useState([]);
  const [fees, setFees] = useState([]);
  const [students, setStudents] = useState([]);
  const [division, setDivision] = useState("A");
  const [searchString, setSearchString] = useState("");
  const [term, setTerm] = useState(config?.currentTerm);
  const [profile, setProfile] = useState(null);
  const [preview, setPreview] = useState(false);
  const [payment_method, setPaymentMethod] = useState("");
  const [fee_type, setFeeType] = useState("");
  const [amount, setAmount] = useState(0.0);
  const [paid_by, setPaidBy] = useState("N/A");
  const [date, setDate] = useState(maxDate());
  const [history, setHistory] = useState([[], 0]);
 
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    message: "",
    open: false,
    type: "",
  });

  useEffect(() => {
    async function readData() {
      await readDocuments({
        path: `/fees/?cid=${sid}`,
        getData: (data) =>
          setFees(
            _.filter(
              data,
              (info) =>
                info.academic_year === config?.academicYear &&
                info.term === config?.currentTerm
            )
          ),
      });

      await readDocuments({
        path: `/student/?id=${sid}`,
        getData: (data) => {
          setStudents(_.filter(data, (dt) => dt.is_active === true));
        },
      });
    }

    readData();
    searchQuery({
      path: "revenue",
      type: "schoolID",
      searchString: sID,
      getData: setSettings,
    });
    
  }, [cls, sid, config]);

  const classStudents = (cls) => {
    if (searchString.trim() !== "") {
      let temp = [];
      const regex = new RegExp(searchString, "i");
      for (const std of students) {
        if (regex.test(std.last_name) || regex.test(std.other_names)) {
          temp.push(std);
        }
      }
      return temp;
    } else {
      return _.filter(students, (sd) => sd.current_class === cls);
    }
  };

  const projectFees = () => {
    const studs = _.filter(
      students,
      (std) => std.current_class === cls && std.division === division
    );
    const sum = _.sumBy(studs, (st) => st.arrears);
    const data = _.filter(
      fees,
      (fs) => fs.class === cls && fs.fee_type === "School Fees"
    );

    const paid = _.sumBy(data, (dt) => dt.amount);

    return [toCurrency(sum), toCurrency(paid)];
  };

  const getHistory = (info) => {
    const data = _.find(students, (std) => std.student_id === info?.student_id);
    setProfile(data);
    setClass(info.current_class);
    setPreview(true);

    return _.filter(
      fees,
      (fs) => fs.student_id === info.student_id && fs.fee_type === fee_type
    );
  };

  const sortHistory = (info) => {
    const fsHistory = getHistory(info);
    const all = _.sortBy(fsHistory, (hs) => new Date(hs.date_paid).getTime());
    const sum = _.sumBy(all, (py) => py.amount);

    if (user.feeRole === "School Fees"){
      let fees = _.filter(all, al => al.fee_type === user.feeRole)
      setHistory([fees, _.sumBy(fees, (py) => py.amount)]);
      return
    }
    if(user.feeRole === "Other Fees"){
      let fees = _.filter(all, al => al.fee_type !== "School Fees")
      setHistory([fees, _.sumBy(fees, (py) => py.amount)]);
      return
    }

    setHistory([all, sum]);
  };

  const printHistory = () => {
    const data = _.filter(
      fees,
      (fs) =>
        fs.class === cls &&
        fs.division === division &&
        fs.academic_year === config.academicYear &&
        fs.term === term &&
        fs.fee_type === fee_type
    );

    generate_history(cls, data, division, term, fee_type);
  };

  const getPayments = async () => {
    let { data } = await axios.get(
      `${proxy}/fees/history/${profile.student_id}`,
      { headers: { dbName, xmail: xmail, docID: userID } }
    );

    return _.filter(
      data,
      (td) =>
        td.term === term &&
        td.academic_year === config.academicYear &&
        td.fee_type === fee_type
    );
  };

  const getOtherFees = async (payment_type, category) => {
    const expectedTotal = _.find(
      settings,
      (st) =>
        st.type === "Revenue" &&
        st.desc === payment_type &&
        (st.category === category || st.category === "General")
    )?.amount;

    const payments = await getPayments();

    const paymentsThisTerm = _.sumBy(payments, (pm) => parseFloat(pm.amount));

    return expectedTotal - (paymentsThisTerm + amount);
  };

  const getBalance = async () => {
    let info = await axios.get(
      `${proxy}/student/get-student/${profile.student_id}`,
      { headers: { dbName, xmail: xmail, docID: userID } }
    );

    const { arrears } = info.data;
    return arrears - amount;
  };

  const processPayment = async (e) => {
    e.preventDefault();
    setLoading(true);
    const {
      last_name,
      other_names,
      student_id,
      current_class,
      contact_1,
      id,
      division,
      class_category,
    } = profile;

    const feesBalance = await getBalance();
    const otherBalance = await getOtherFees(fee_type, class_category);
    const balance = fee_type === "School Fees" ? feesBalance : otherBalance;

    // balance becomes the new arrears.
    const data = {
      id: idv4().slice(0, 30).replaceAll("-", "").trim(),
      last_name,
      division,
      phone: contact_1,
      timeStamp: new Date().getTime(),
      amount,
      first_name: other_names,
      student_id,
      paymentMethod: payment_method,
      date_paid: new Date(date).toISOString(),
      class: current_class,
      payment_id: new Date().getTime().toString(),
      fee_type,
      year: new Date().getFullYear(),
      term,
      academic_year: config.academicYear,
      balance,
      receiver: user.name || auth.currentUser.uid,
      paid_by,
    };

    // get latest from history
    uploadData({
      path: "/fees/",
      data,
    })
      .then(() => {
        setResponse({
          message: "Fees Successfully Added",
          open: true,
          type: "success",
        });

        // update Arrears with Balance for student.

        if (fee_type === "School Fees") {
          updateData({
            path: "/student",
            id,
            data: { arrears: parseFloat(balance), is_active: true },
          }).then(() => {
            setProfile((prev) => ({ ...prev, arrears: balance }));
          });
          setLoading(false);
        }

        setAmount(0);
        setPaidBy("N/A");
        setLoading(false);
        setSID(new Date().getSeconds());

        sortHistory(profile);

        // proceed to send message
      })
      .catch((err) => {
        let { message } = err.response.data;
        setResponse({
          message: message || err.message,
          open: true,
          type: "error",
        });
        setLoading(false);
      });
  };

  const removeFees = async (id, amount, fee_type) => {
    const { data, status } = await deleteData({
      path: "/fees",
      id,
    });

    // replace student arrears last payment arrears.
    if (status === 200 && fee_type === "School Fees") {
      // make another call for the last payment of the student.
      const { arrears, id } = profile;
      updateData({
        path: "/student",
        id,
        data: { arrears: arrears + amount, is_active: true },
      });
    }

    setSID(new Date().getSeconds());
    sortHistory(profile);

    setResponse({
      type: status === 200 ? "success" : "error",
      open: true,
      message: status === 200 ? "You've deleted a record" : data.message,
    });
  };
  const payTypes = () => {
    const types = _.filter(settings, (st) => st.type === "Revenue");
    const uniques = [];
    types.forEach((type) => {
      let exist = _.find(uniques, (un) => un.desc === type.desc);
      if (exist === undefined) {
        uniques.push(type);
      }
    });

    return uniques;
  };

  const findHistory = async () => {
   const { data} = await axios.get(
      `${proxy}/fees/history/${profile.student_id}`,
      { headers: { dbName, xmail: xmail, docID: userID } }
    );

    generate_student_history(profile, data)

    // console.log(data);
  }

  return (
    <div className="h-[90vh] relative overflow-hidden flex-1 bg-gray-100">
      <Screen
        banner={banner}
        user={user}
        changeTab={setClass}
        currentTab={cls}
        selectTerm={setTerm}
        setFeeType={setFeeType}
        feeType={fee_type}
        selectDivision={setDivision}
        term={term}
        payTypes={payTypes}
        division={division}
      />
      <div className="flex  h-[80vh] w-[90vw] overflow-hidden">
        {/* Student's List */}
        <div className="flex flex-col w-[25vw] shrink-0  z-[200] bg-gray-100">
          <div className="p-2 w-full">
            <p className="text-xs">Students</p>
            <h3 className="font-bold text-xl">{cls}</h3>
            <div className="flex items-center my-2 p-2 bg-white rounded-md">
              <BiSearch className="text-xl text-gray-400" />
              <input
                onChange={(e) => setSearchString(e.target.value)}
                type="search"
                className="w-full text-sm p-2"
                placeholder="Search Student"
              />
            </div>
          </div>
          <div className="p-2 max-h-[60vh] overflow-y-auto gap-2">
            {classStudents(cls).map((info, idx) => (
              <Student
                setStudent={sortHistory}
                info={info}
                profile={profile}
                key={idx}
              />
            ))}
          </div>
          {/* Search Bar */}
        </div>

        {/* Payment Form */}

        <div className="flex flex-col w-[35vw] p-2 bg-gray-200">
          <div>
            <p className="text-xs">{cls}</p>
            <h3 className="font-bold text-xl">Payment Form</h3>
          </div>
          {/* Payment Form */}
          <form onSubmit={processPayment} className="flex flex-col p-5 gap-5">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-xs pb-2">
                Student Name
              </label>
              <input
                value={
                  profile !== null
                    ? profile.last_name + " " + profile.other_names
                    : " "
                }
                onChange={(e) => {}}
                type="text"
                id="name"
                className="p-2"
                placeholder="Name of Student"
              />
            </div>
            <div className="flex w-full">
              <div className="flex flex-col w-1/2 mr-2">
                <label htmlFor="pay_method" className="text-xs pb-2">
                  Payment Method
                </label>
                <select
                  value={payment_method}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  required
                  id="pay_method"
                  className="p-[10px]"
                >
                  <option value=""> Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Bank"> Bank</option>
                  <option value="Momo"> Mobile Money</option>
                </select>
              </div>

              <div className="flex flex-col w-1/2 mr-2">
                <label htmlFor="pay_date" className="text-xs pb-2">
                  Payment Date
                </label>
                <input
                  max={maxDate()}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                  className="p-2"
                />
              </div>
            </div>

            <div className="flex w-full">
              <div className="flex flex-col w-1/2 mr-2">
                <label htmlFor="amount" className="text-xs pb-2">
                  Amount Paid
                </label>
                <input
                  value={amount}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  type="number"
                  pattern="[0-9]+"
                  step={0.1}
                  id="amount"
                  className="p-2"
                  placeholder="Amount"
                />
              </div>

              <div className="flex flex-col w-1/2 ">
                <label htmlFor="paid_by" className="text-xs pb-2">
                  Paid By
                </label>
                <input
                  value={paid_by}
                  type="text"
                  onChange={(e) => setPaidBy(e.target.value)}
                  id="paid_by"
                  className="p-2"
                  placeholder="Paid By"
                />
              </div>
            </div>

            <button
              disabled={
                loading ||
                profile === null ||
                !fee_type ||
                term === undefined ||
                user.isReadOnly
              }
              className="bg-gray-500  w-fit p-2 flex justify-center text-white text-sm"
            >
              {loading ? <Spin /> : "Save Record"}
            </button>
          </form>
        </div>

        {/* Student Information Display */}
        <div className="w-[30vw] shrink-0 bg-white">
          {!preview ? (
            <>
              <div className="flex w-full bg-white">
                <div className="w-1/2 p-3 space-y-2">
                  <FaChartPie className="text-3xl text-green-700" />
                  <div>
                    <h2 className="text-xs">Fees Paid</h2>
                    <h1 className="text-xl">{projectFees()[1]}</h1>
                  </div>
                </div>
                <div className="w-1/2 p-3 space-y-2">
                  <BiPieChart className="text-3xl text-red-700" />

                  <div>
                    <h2 className="text-xs">Arrears</h2>
                    <h1 className="text-xl">{projectFees()[0]}</h1>
                  </div>
                </div>
              </div>

              {/* Debtors | Full Payments */}
              <div className="flex ">
                <button className="w-1/2 bg-red-600 text-sm text-white p-1">
                  {" "}
                  Debtors
                </button>
                <button
                  onClick={() => printHistory()}
                  className="w-1/2 bg-green-800 text-white text-sm  p-1"
                >
                  {" "}
                  Print History
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-col w-full items-center p-2">
              {/* Profile History */}
              <div className="relative flex items-center flex-col">
                <button
                  title="View Class Profile"
                  onClick={() => setPreview(false)}
                  className="absolute right-3 top-2 z-50 p-2 text-sm bg-gray-800 rounded-full"
                >
                  <AiOutlineEyeInvisible className="text-yellow-500 text-lg" />
                </button>

                <img
                  src={profile.photo}
                  alt={profile.last_name + " " + profile.other_names}
                  className="w-28 h-28 object-cover rounded-full"
                />
                <p className="text-lg">
                  {profile.last_name + " " + profile.other_names}
                </p>
                <p className="text-xs">Payment History</p>
              </div>
              <div className="max-h-96 w-full overflow-y-auto">
                <table className="table-auto w-full my-2 ">
                  <thead className="border-b sticky top-0 bg-white border-b-black">
                    <tr className="font-bold text-sm">
                      <td className="p-2">Type</td>
                      <td className="p-2">Amount</td>
                      <td className="p-2">Balance</td>
                      <td className="p-2">Date</td>
                      <td className="p-2">Actions</td>
                    </tr>
                  </thead>
                  <tbody>
                    {history[0]?.map((info, idx) => (
                      <tr
                        key={idx}
                        className={`"text-xs border-b ${
                          idx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }`}
                      >
                        <td className="p-2">{info.fee_type}</td>
                        <td className="p-2">{toCurrency(info.amount)}</td>
                        <td className="p-2">{toCurrency(info.balance)}</td>
                        <td className="p-2">{info.date_paid?.slice(0, 10)}</td>
                        <td className="p-2">
                          <button
                            disabled
                            onClick={() => generate_receipt(info)}
                            className="mr-2"
                          >
                            <BsPrinter size={20} color="blue" />
                          </button>

                          {!user.isReadOnly && (
                            <button
                              onClick={() =>
                                removeFees(info.id, info.amount, info.fee_type)
                              }
                            >
                              <BiTrash size={20} color="red" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="w-full ">
                    <tr>
                      <td
                        colSpan={2}
                        className="text-sm text-green-500 p-2 font-light"
                      >
                        Sum: {toCurrency(history[1])}
                      </td>
                      <td
                        colSpan={3}
                        className="font-bold text-red-500 text-sm p-2 text-right"
                      >
                        Arrears:{toCurrency(profile.arrears)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <button
                          onClick={() =>
                            // generate_student_history(profile, history[0])
                            findHistory()
                          }
                          // disabled={history[0]?.length < 1}
                          className="text-xs  bg-gray-600 text-white w-full p-2 mx-auto mt-5"
                        >
                          Print Statement
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>

        {response.open && (
          <Feedback
            {...response}
            setOpen={() =>
              setResponse({
                message: "",
                open: false,
                type: "",
              })
            }
          />
        )}
      </div>

      <AnimatePresence>
        {alert && <Broadcast senderID={senderID} />}
      </AnimatePresence>
      <button
        onClick={() => setAlert(!alert)}
        className="flex items-center justify-center rounded-full bg-red-500 fixed bottom-5 right-10 w-12 h-12"
      >
        <HiBellAlert size={30} color="white" />
      </button>
    </div>
  );
}
