import React, { useState } from "react";
import { HiBellAlert } from "react-icons/hi2";
import { SlReload } from "react-icons/sl";
import { classes, divisions, terms } from "../data";
import { NavLink } from "react-router-dom";

export const MenuItem = ({ Icon, desc, active , to }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `flex gap-2  items-center my-1 ${
        isActive ? "text-orange-300 font-bold" : "text-gray-50"
      } p-2 `
    }
  >
    <Icon
      color={active ? 'orange' :'gray'}
      size={24}
    />{" "}
    <p className={`text-sm`}>{desc}</p>
  </NavLink>
);

export const Topbar = ({ title, user, notes }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex  z-[5000]  h-[70px] shrink-0 justify-between items-center bg-gray-800 w-full sticky top-0">
      <h3 className="pl-2 text-xl text-gray-50">{title}</h3>

      <div className="flex justify-end">
        <div className="flex space-x-5 mr-2">
          <button onClick={() => window.location.reload()}>
            <SlReload className="text-2xl text-gray-500 hover:animate-pulse hover:text-white" />
          </button>
          <button onClick={() => setShow(!show)} className="relative">
            {notes.length > 0 && (
              <div className="absolute w-4 h-4 rounded-full bg-red-700 right-1 top-0">
                <p className="text-xs text-white">{notes?.length}</p>
              </div>
            )}
            <HiBellAlert className="text-3xl text-gray-50" />
          </button>

          <div className="flex">
            <div className="rounded-full w-12 h-12 flex items-center justify-center">
              <img
                src={user?.photo}
                alt="Accountant"
                className="w-10 h-10 object-contain  rounded-sm"
              />
            </div>

            <div className=" px-1">
              <p className="text-md text-white">{user?.name}</p>
              <p className="text-xs text-gray-50">{user?.role}</p>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div className="absolute p-2 bg-white shadow-md rounded-b-md overflow-y-auto h-56 bg-red w-[250px] top-16 right-20">
          <h3 className="mb-2">Notifications</h3>
          {notes?.map((nt) => (
            <div className="flex items-center space-x-2">
              <HiBellAlert className="text-gray-300 text-md" />
              <div className="p-1">
                <p className="text-gray-500 text-xs">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Necessitatibus commodi magnam architecto dolor in.
                </p>
                <p className="text-right text-xs text-red-500">
                  {nt.date.slice(0, 10)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const Screen = ({
  changeTab,
  selectTerm,
  term,
  user,
  setFeeType,
  feeType,
  division,
  selectDivision,
  banner,
  payTypes,
}) => (
  <div
    className="h-[120px] w-full bg-gray-500/50 bg-cover bg-center bg-repeat-x p-3"
    style={{ backgroundImage: `url(${banner})` }}
  >
    <div className="flex space-x-3 w-[300px]">
      <select
        value={feeType}
        required
        onChange={(e) => setFeeType(e.target.value)}
        className="p-2 text-sm"
      >
        <option value="">Payment Type</option>
        <option
          value="School Fees"
          disabled={ !["School Fees", "All"].includes(user?.feeRole)}
        >
          School Fees
        </option>
        {payTypes().map((info) => (
          <option value={info.desc} disabled={user?.feeRole === "School Fees"}>
            {info.desc}
          </option>
        ))}
      </select>

      <select
        onChange={(e) => changeTab(e.target.value)}
        className="p-1 rounded-sm text-sm"
      >
        <option value="">No Class Selected</option>
        {classes.map((cls, idx) => (
          <option key={idx} value={cls.value}>
            {cls.text}
          </option>
        ))}
      </select>

      <select
        value={term}
        onChange={(e) => selectTerm(e.target.value)}
        className="p-1 rounded-sm text-sm"
      >
        <option value="">No Term Selected</option>
        {terms.map((term) => (
          <option key={term.text} value={term.value}>
            {term.text}
          </option>
        ))}
      </select>

      <select
        value={division}
        onChange={(e) => selectDivision(e.target.value)}
        className="p-1 rounded-sm w-fit text-sm"
      >
        <option value="">Group</option>
        {divisions.map((info) => (
          <option key={info.key} value={info.value}>
            {info.text}
          </option>
        ))}
      </select>

     
    </div>
  </div>
);
