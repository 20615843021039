import { FcCalendar } from "react-icons/fc";
import { HiOutlinePrinter } from "react-icons/hi";
import { BsCashCoin } from "react-icons/bs";
import { TbSettings } from "react-icons/tb";
import { toCurrency } from "../functions";

import generate_status from "../generators/generate-fees-status";

export const Student = ({ setStudent, info, profile, type }) => (
  <div
    onClick={() => (type !== "Fees" ? setStudent(info) : {})}
    className={`cursor-pointer flex flex-col border-b ${
      profile?.student_id === info?.student_id ? "bg-green-200" : "bg-gray-50"
    } rounded-sm shadow-sm p-2 w-full`}
  >
    <p className="text-lg truncate w-[80%]">{`${info?.last_name}  ${info.other_names}`}</p>
    <p className="text-xs">
      {info?.gender} - {info.current_class}
    </p>
  </div>
);

export const Calendar = ({ info }) => (
  <div className="flex items-center space-x-2">
    <FcCalendar className="text-2xl" />
    <div className="container">
      <p className="text-gray-400 text-xs">{info.month}</p>
      <h3 className="text-sm">{toCurrency(info.sum)}</h3>
    </div>
  </div>
);

export const Debtors = ({ cls, debtors, division, group, term, year }) => (
  <div className="flex items-center gap-2 w-[48%] bg-gray-400/90 text-white  rounded-sm justify-between shadow-md p-2 border">
    <div className="container flex space-x-2 ">
      <div>
        <p className="text-sm">{cls}</p>
        <span className="flex text-xs space-x-2">
          <p>
            {group}: {debtors.length}
          </p>
        </span>
      </div>
    </div>

    <button
      title="Print"
      disabled={debtors.length < 1}
      onClick={() => generate_status(cls, debtors, division, term, year, group)}
      className="text-lg text-white rounded-full bg-gray-600 p-1"
    >
      <HiOutlinePrinter />
    </button>
  </div>
);

export const FeeTiles = ({ desc, modifyFees }) => (
  <div className="flex border border-slate-300 text-gray-400 p-2 rounded-md items-center justify-between w-full space-x-2">
    <div className="flex items-center space-x-2">
      <BsCashCoin className="text-xl" /> <p>{desc}</p>
    </div>
    <button onClick={() => modifyFees(desc, null)}>
      <TbSettings />
    </button>
  </div>
);

export const Card = ({ title, description, bg }) => (
  <div className={`h-20 p-2 ${bg} justify-center space-y-2  w-1/4 shadow-md rounded flex flex-col`}>
    <p className="text-xs whitespace-nowrap">{title}</p>
    <h1 className="text-lg whitespace-nowrap">{description}</h1>
  </div>
);

